@import "flickity/dist/flickity.min.css";
@import "splitting/dist/splitting.css";
@import "splitting/dist/splitting-cells.css";

@import "./_fonts";
@import "./_text-anim";

.flickity-viewport {
  width: 100%;
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  &:hover {
    color: #000;
  }
}

.flickity-prev-next-button.previous {
  left: -40px;
}

.flickity-prev-next-button.next {
  right: -40px;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  color: #000;
  padding: 0;
  margin: 0;
  --highlight-color: #ea7c47;
}

.container {
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
  max-width: 960px;
  margin: 0 auto;
}

h1 {
  font-weight: 300;
  font-size: 35px;
  color: #000;
  margin-top: 0;
  font-family: "Chronicle Display", serif;

  @media screen and (min-width: 576px) {
    font-size: 50px;
  }

  @media screen and (min-width: 768px) {
    font-size: 60px;
  }

  strong {
    font-style: italic;
    font-weight: 600;
    color: var(--highlight-color);
  }
  .line {
    display: block;
  }
}

a {
  color: #000;

  &:hover {
    color: var(--highlight-color);
  }
}

p {
  margin-bottom: 30px;
}

.button {
  display: inline-block;
  background-color: var(--highlight-color);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 4px 4px 0 rgba(50, 50, 93, 0.11);
  border-radius: 4px;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;

  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken(#ea7c47, 15);
    color: #ffffff;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 0;
  width: 100%;
  align-items: center;
}

.main {
  margin: 60px 0 30px;
  display: flex;
  flex-direction: column;

  .splitting {
    display: block;
    overflow: hidden;
  }

  h1 {
    margin-bottom: 60px;
  }

  ul {
    padding: 0;
    margin: 0 0 60px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    font-size: 18px;
    line-height: 1.5;
    color: #909090;

    &::after {
      content: "•";
      margin: 0 10px;
    }

    &:last-child::after {
      display: none;
    }

    @media screen and (min-width: 576px) {
      font-size: 20px;
    }

    @media screen and (min-width: 768px) {
      font-size: 25px;
    }

    &.is-highlighted span {
      color: var(--highlight-color);
      font-weight: 700;
    }
  }
}
.logo {
  display: flex;
}

.logo-image {
  width: 40px;
  margin-right: 10px;
  color: var(--highlight-color);

  svg {
    width: 40px;
    height: 40px;
  }
}

.logo-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.logo-text-title {
  font-weight: 700;
  color: #000;
}

.logo-text-subtitle {
  color: #909090;
}

.footer {
  width: 100%;

  p {
    font-style: italic;
    font-weight: 400;
    font-family: "Chronicle Display", serif;
    color: #000;
    margin-bottom: 10px;
  }
}

.clients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  filter: grayscale(100);
  opacity: 0.5;

  img {
    height: 38px;
    margin-right: 40px;
  }
}

.persons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 30px;
  cursor: pointer;

  img {
    height: 70px;
    border-radius: 50%;
    box-shadow: 0px 0px 40px #fff;
    transition: 0.3s ease box-shadow;
  }

  &:hover {
    .person-name {
      color: var(--highlight-color);
      font-weight: 600;
    }
    img {
      box-shadow: 0px 0px 100px var(--highlight-color);
    }
  }
}

.person-name {
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  font-family: "Chronicle Display", serif;
  font-style: italic;
}

.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
  }
  .cursor__inner {
    fill: var(--cursor-fill);
    stroke: var(--cursor-stroke);
    stroke-width: var(--cursor-stroke-width);
  }
}
