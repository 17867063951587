:root {
  --radius-sm: calc(var(--radius, 0.25em) / 2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em) * 2);
  --shadow-xs: 0 0.1px 0.3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
  --shadow-sm: 0 0.3px 0.4px rgba(0, 0, 0, 0.025),
    0 0.9px 1.5px rgba(0, 0, 0, 0.05), 0 3.5px 6px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 0.9px 1.5px rgba(0, 0, 0, 0.03),
    0 3.1px 5.5px rgba(0, 0, 0, 0.08), 0 14px 25px rgba(0, 0, 0, 0.12);
  --shadow-lg: 0 1.2px 1.9px -1px rgba(0, 0, 0, 0.014),
    0 3.3px 5.3px -1px rgba(0, 0, 0, 0.038),
    0 8.5px 12.7px -1px rgba(0, 0, 0, 0.085),
    0 30px 42px -1px rgba(0, 0, 0, 0.15);
  --shadow-xl: 0 1.5px 2.1px -6px rgba(0, 0, 0, 0.012),
    0 3.6px 5.2px -6px rgba(0, 0, 0, 0.035),
    0 7.3px 10.6px -6px rgba(0, 0, 0, 0.07),
    0 16.2px 21.9px -6px rgba(0, 0, 0, 0.117),
    0 46px 60px -6px rgba(0, 0, 0, 0.2);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);
}

.text-anim__wrapper {
  position: relative;
  display: inline-block;
}

.text-anim__word {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: inline-block;
  white-space: nowrap;
}

.text-anim__word--in {
  position: relative;
  z-index: 2;
}

// --rotate
.text-anim--rotate {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    perspective: 300px;
  }

  .text-anim__word {
    opacity: 0;
    backface-visibility: hidden;
    transform-origin: 50% 100%;
    transform: rotateX(-180deg);
    transition: opacity calc(var(--text-anim-duration) / 3) var(--ease-out),
      transform var(--text-anim-duration) var(--ease-out-back);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: rotateX(0deg);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: rotateX(180deg);
    transition: opacity calc(var(--text-anim-duration) / 6) var(--ease-out),
      transform var(--text-anim-duration) var(--ease-out-back);
  }
}

// --loader
.text-anim--loader {
  --text-anim-duration: 2.5s;
  --text-anim-pause: 0s;

  .text-anim__word {
    color: transparent;
    transition: color 0.2s;

    &::after {
      // animated loader
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
      background-color: currentColor;
      transform-origin: left center;
      transform: translateY(100%) scaleX(0);
      transition: transform var(--text-anim-duration) var(--ease-out);
    }
  }

  .text-anim__word--in {
    color: currentColor;

    &::after {
      transform: translateY(100%) scaleX(1);
    }
  }

  .text-anim__word--out {
    color: transparent;

    &::after {
      transition: none;
      transform: translateY(100%) scaleX(0);
    }
  }
}

// --slide
.text-anim--slide {
  --text-anim-duration: 0.5s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    padding: 0.1em 0;
  }

  .text-anim__word {
    height: 100%;
    transform: translateY(-100%);
  }

  .text-anim__word--in,
  .text-anim__word--out {
    animation-duration: var(--text-anim-duration);
    animation-timing-function: var(--ease-out-back);
  }

  .text-anim__word--in {
    animation-name: text-anim-slide-in;
    animation-fill-mode: forwards;
  }

  .text-anim__word--out {
    animation-name: text-anim-slide-out;
  }
}

@keyframes text-anim-slide-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes text-anim-slide-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

// --zoom
.text-anim--zoom {
  --text-anim-duration: 0.85s;
  --text-anim-pause: 2.5s;

  .text-anim__wrapper {
    perspective: 300px;
  }

  .text-anim__word {
    opacity: 0;
    transform: translateZ(100px);
    transition: opacity var(--text-anim-duration),
      transform var(--text-anim-duration) var(--ease-out);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: translateZ(0);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: translateZ(-100px);
  }
}

// --push
.text-anim--push {
  --text-anim-duration: 0.65s;
  --text-anim-pause: 2.5s;

  .text-anim__word {
    opacity: 0;
    transform: translateX(-100px);
    transition: none;
  }

  .text-anim__word--in,
  .text-anim__word--out {
    transition: opacity var(--text-anim-duration),
      transform var(--text-anim-duration) var(--ease-out-back);
  }

  .text-anim__word--in {
    opacity: 1;
    transform: translateX(0);
  }

  .text-anim__word--out {
    opacity: 0;
    transform: translateX(100px);
  }
}

// --clip
.text-anim--clip {
  --text-anim-duration: 0.7s;
  --text-anim-pause: 2s;
  --text-anim-border-width: 2px;

  .text-anim__wrapper {
    overflow: hidden;
    vertical-align: top;

    &::after {
      // right border
      content: "";
      position: absolute;
      right: 0;
      top: calc(50% - 0.7em);
      height: 1.4em;
      width: var(--text-anim-border-width);
      background-color: var(--color-accent);
    }
  }

  .text-anim__wrapper--pulse {
    &::after {
      animation: text-anim-pulse 0.8s infinite;
    }
  }

  .text-anim__word {
    opacity: 0;
    padding-right: var(--text-anim-border-width);
  }

  .text-anim__word--in {
    opacity: 1;
  }
}

@keyframes text-anim-pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}
