@font-face {
  font-family: "Chronicle Display";
  src: url("../fonts/ChronicleDisplay-Roman.woff2") format("woff2"),
    url("../fonts/ChronicleDisplay-Roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Chronicle Display";
  src: url("../fonts/ChronicleDisplay-Italic.woff2") format("woff2"),
    url("../fonts/ChronicleDisplay-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Chronicle Display Semi";
  src: url("../fonts/ChronicleDisplay-SemiItalic.woff2") format("woff2"),
    url("../fonts/ChronicleDisplay-SemiItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}
